/**
 * Created by swind on 01/11/2017.
 */
$('.showdocuments').on('click', function(e) {
    e.preventDefault();
    $(this).next('ul').toggleClass('open');
});

$(document).ready(function() {
    var title = document.getElementById('CCIPlaceHolder_Content');
    if (title) {
        title = title.querySelector('h1');
        if (title && title.innerHTML == 'Kennisgroep Managers Facilitaire Zaken') {
            title.innerHTML = 'Kennisgroep Facilitair Managers';
        }
    }


    // remove member list underneath Events
    var members = $('.sectionMembers');
    var Documents = $('.sectionDocuments');
    if ($('.sectionPreviousEvents').length) {
        (members).insertAfter('.sectionPreviousEvents');
        (Documents).insertAfter('.sectionPreviousEvents');
    } else {
        (members).insertAfter('.sectionUpcomingEvents');
        (Documents).insertAfter('.sectionUpcomingEvents');
    }

});

function hisProfile() {
    var profileElem = document.getElementById('Block_1_Show_Person_Personal_Information_SN_PersonPersonalInformation');
    var rows = profileElem.getElementsByTagName('div');
    var arrRows = [];

    for (var i = 0; i < rows.length; i++) {
        var className = rows[i].className;

        switch (className) {
            case "SN-Block-PersonalInformation-Item SN-Block-PersonalInformation-Item-Prive-Medewerker SN-Block-PersonalInformation-Item-Prive-Medewerker-Bedrijfsnaam":
                arrRows[0] = [rows[i].querySelector("strong").innerHTML, rows[i].querySelector("span").innerHTML];
                break;
            case "SN-Block-PersonalInformation-Item SN-Block-PersonalInformation-Item-Prive-Medewerker SN-Block-PersonalInformation-Item-Prive-Medewerker-Functie":
                arrRows[1] = [rows[i].querySelector("strong").innerHTML, rows[i].querySelector("span").innerHTML];
                break;
            case "SN-Block-PersonalInformation-Item SN-Block-PersonalInformation-Item-Prive-Medewerker SN-Block-PersonalInformation-Item-Prive-Medewerker-Email":
                arrRows[2] = [rows[i].querySelector("strong").innerHTML, rows[i].querySelector("span").innerHTML];
                break;
            case "SN-Block-PersonalInformation-Item SN-Block-PersonalInformation-Item-Prive-Medewerker SN-Block-PersonalInformation-Item-Prive-Medewerker-Telefoon":
                arrRows[3] = [rows[i].querySelector("strong").innerHTML, rows[i].querySelector("span").innerHTML];
                break;
            case "SN-Block-PersonalInformation-Item SN-Block-PersonalInformation-Item-Prive-Medewerker SN-Block-PersonalInformation-Item-Prive-Medewerker-Adres":
                arrRows[4] = [rows[i].querySelector("strong").innerHTML, rows[i].querySelector("span").innerHTML];
                break;
            case "SN-Block-PersonalInformation-Item SN-Block-PersonalInformation-Item-Prive-Medewerker SN-Block-PersonalInformation-Item-Prive-Medewerker-Postcode":
                arrRows[5] = [rows[i].querySelector("strong").innerHTML, rows[i].querySelector("span").innerHTML];
                break;
            case "SN-Block-PersonalInformation-Item SN-Block-PersonalInformation-Item-Prive-Medewerker SN-Block-PersonalInformation-Item-Prive-Medewerker-Plaats":
                arrRows[6] = [rows[i].querySelector("strong").innerHTML, rows[i].querySelector("span").innerHTML];
                break;
        }
    }

    profileElem.innerHTML = "";

    var tableHolder = document.createElement("table");
    tableHolder.setAttribute("style", "width:50%;");
    for (var j = 0; j < arrRows.length; j++) {
        if (arrRows[j]) {
            console.log(arrRows[j][0]);

            var tableRow = document.createElement("tr");
            var cellOne = document.createElement("td");
            var cellTwo = document.createElement("td");
            cellOne.innerHTML = arrRows[j][0];
            cellTwo.innerHTML = arrRows[j][1];
            tableRow.appendChild(cellOne);
            tableRow.appendChild(cellTwo);

            tableHolder.appendChild(tableRow);


            //profileElem.getElementsByTagName('table')[0].appendChild(tableRow);
        }
    }
    document.getElementById('Block_1_Show_Person_Personal_Information').appendChild(tableHolder);
    document.getElementById('Block_1_Show_Person_Personal_Information').style.display = "block";
}

function myProfile() {
    var profileElem = document.getElementById('Block_EditProfile_SN_Medewerkers');
    var rows = profileElem.getElementsByTagName('tr');
    var arrRows = [];

    for (var i = 0; i < rows.length; i++) {
        var children = rows[i].childNodes;
        var className = children[1].className;

        switch (className) {
            case "SN_MedewerkerData_Label SN_MedewerkerData_Bedrijfsnaam_Label Employer":
                arrRows[0] = [children[1].innerHTML, children[2].innerHTML];
                break;
            case "SN_MedewerkerData_Label SN_MedewerkerData_Functie_Label Employee":
                arrRows[1] = [children[1].innerHTML, children[2].innerHTML];
                break;
            case "SN_MedewerkerData_Label SN_MedewerkerData_Email_Label Employee":
                arrRows[2] = [children[1].innerHTML, children[2].innerHTML];
                break;
            case "SN_MedewerkerData_Label SN_MedewerkerData_Email_Label Employee":
                arrRows[3] = [children[1].innerHTML, children[2].innerHTML];
                break;
            case "SN_MedewerkerData_Label SN_MedewerkerData_Telefoon_Label Employer":
                arrRows[4] = [children[1].innerHTML, children[2].innerHTML];
                break;
            case "SN_MedewerkerData_Label SN_MedewerkerData_Mobiel_Label Employee":
                arrRows[5] = [children[1].innerHTML, children[2].innerHTML];
                break;
            case "SN_MedewerkerData_Label SN_MedewerkerData_Adres_Label Employer":
                arrRows[6] = [children[1].innerHTML, children[2].innerHTML];
                break;
            case "SN_MedewerkerData_Label SN_MedewerkerData_Postcode_Label Employer":
                arrRows[7] = [children[1].innerHTML, children[2].innerHTML];
                break;
            case "SN_MedewerkerData_Label SN_MedewerkerData_Plaats_Label Employer":
                arrRows[8] = [children[1].innerHTML, children[2].innerHTML];
                break;
        }
    }

    profileElem.getElementsByTagName('table')[0].innerHTML = "";
    for (var j = 0; j < arrRows.length; j++) {
        if (arrRows[j]) {
            var tableRow = document.createElement("tr");
            var cellOne = document.createElement("td");
            var cellTwo = document.createElement("td");
            //arrRows[j][1]
            cellOne.setAttribute("class", "a");
            cellTwo.setAttribute("class", "a");
            cellOne.innerHTML = arrRows[j][0];
            cellTwo.innerHTML = arrRows[j][1];
            tableRow.appendChild(cellOne);
            tableRow.appendChild(cellTwo);
            profileElem.getElementsByTagName('table')[0].appendChild(tableRow);
        }
    }
    document.getElementById('Block_EditProfile_SN_Medewerkers').style.display = "block";
}

$(document).ajaxStop(function() {
    changeTranslations();

    if ($('.SN_MedewerkerData_Label.SN_MedewerkerData_Email_Label.Employee')) {
        $('.SN_MedewerkerData_Label.SN_MedewerkerData_Email_Label.Employee').html('E-mail');
    }
    if ($('#Block_SubScribeNewsletter_Block_SubScribeNewsletter_lblemailadresprive').length > 0) {
        $('#Block_SubScribeNewsletter_Block_SubScribeNewsletter_lblemailadresprive').html('E-mailadres');
    }
    if ($('.SN-Block-PersonalInformation-Item.SN-Block-PersonalInformation-Item-Prive-Medewerker.SN-Block-PersonalInformation-Item-Prive-Medewerker-Email')) {
        $('.SN-Block-PersonalInformation-Item.SN-Block-PersonalInformation-Item-Prive-Medewerker.SN-Block-PersonalInformation-Item-Prive-Medewerker-Email').find('strong').html('E-mail');
    }
    if ($('#Block_EditProfile_SN_Medewerkers').length > 0) {
        myProfile();
    }

    if ($('#Block_1_Show_Person_Personal_Information_SN_PersonPersonalInformation').length > 0) {
        hisProfile();
    }
    $('.treeview .folderTrigger').on('click', function() {
        $(this).next('ul').toggleClass('open');
        console.log('click');
    });

    //workgroup meetings archive grouped
    if($(".sectionPreviousEvents")){
        $(".sectionPreviousEvents .workgroup-meeting-year").each(function(){
            $(this).on('click', function(){            
                $(this).toggleClass("active");
                $(this).next().toggleClass("show-meeting");
            })
        });    
    }
});

function changeTranslations(){
    console.log('test');
    var retrievedmsg = $(".loginContainer #lbl_retrievedmsg");
    if (retrievedmsg){
        retrievedmsg.text("Als uw gegevens bij ons bekend zijn ontvangt u een e-mail om uw wachtwoord te resetten.");
    }
}